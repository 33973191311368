import localesEn from "./en/faq_page.json";
import localesEs from "./es/faq_page.json";
import localesFr from "./fr/faq_page.json";
import localesKo from "./ko/faq_page.json";
import localesRu from "./ru/faq_page.json";
import localesZh from "./zh/faq_page.json";
import localesPt from "./pt/faq_page.json";
import localesDe from "./de/faq_page.json";
import localesJa from "./ja/faq_page.json";

export default function getFaqLocales(locale) {
	switch (locale) {
		case "pt":
			return localesPt;
		case "es":
			return localesEs;
		case "ru":
			return localesRu;
		case "fr":
			return localesFr;
		case "ko":
			return localesKo;
		case "zh":
			return localesZh;
		case "de":
			return localesDe;
		case "ja":
			return localesJa;
		default:
			return localesEn;
	}
}
